import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import tst1 from '../../images/testimonials/img-1.jpg'
import tst2 from '../../images/testimonials/img-2.jpg'
import tst3 from '../../images/testimonials/img-3.jpg'
import tst4 from '../../images/testimonials/img-4.jpg'
import tst5 from '../../images/testimonials/img-5.jpg'
import tst6 from '../../images/testimonials/img-6.jpg'


import './style.css'

class Testimonials extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: true,
            speed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            fade:true,
        };

        const testWrap = [
            {
               tImg:tst1,
               des:"We’ve relied on Tech Support Inc. for all our IT infrastructure needs over the past year, and the results speak for themselves. Our systems are more reliable than ever, and their proactive monitoring has kept us ahead of potential issues. Their support team is always responsive, no matter the hour, which is invaluable for our high-demand operations. We couldn't recommend them more highly",
               name:"James Harcour",
               title:"- IT Director, Mayfair Holdings Ltd",
            },
            {
               tImg:tst2,
               des:'Tech Support Inc. has transformed the way we approach IT management. Their in-depth knowledge of infrastructure and security has given us complete peace of mind, and our systems have never run smoother. Every consultant we’ve worked with has been professional, knowledgeable, and efficient. Our whole team trusts them implicitly with our IT.',
               name:"Sarah Kinsley",
               title:"– Operations Manager, Westminster Financial Partners",
            },
            {
               tImg:tst3,
               des:'Their proactive approach to managing our IT needs has saved us from potential downtimes. The team is professional and always available',
               name:"William Cooper",
               title:"- CEO, Kensington Luxury Properties",
            },

            {
                tImg:tst4,
                des:'We were facing constant tech issues that hampered productivity, but since Tech Support Inc. came on board, our systems have been seamless. Their team is responsive and truly goes the extra mile. The transition was smooth, and their ongoing support has been exceptional. They understand our business needs inside out. We trust them implicitly and are delighted to have them as our IT partner.',
                name:"Daniel Voss",
                title:"- Managing Partner, Marylebone Law Associates",
             },

             {
                tImg:tst4,
                des:'Tech Support Inc. has been instrumental in optimizing our workflow. From setting up robust workstations to handling network issues, they’ve shown an incredible depth of expertise. Their personalized approach to IT support feels tailored exactly to our unique needs. They’re reliable, discreet, and professional – everything we could ask for in an IT partner.',
                name:"Daniel Voss",
                title:"- Studio Manager, Chelsea Creative Studios",
             },
          
        ]
        return (
            <div className="wpo-testimonial-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="wpo-testimonial-single-img">
                                <div className="wpo-testimonial-active">
                                    <Slider {...settings}>
                                        {testWrap.map((item, tst) => (
                                            <div className="item" key={tst}>
                                                <div className="wpo-testimonial-img">
                                                    <img src={item.tImg} alt="tesimonial"/>
                                                </div>
                                                <div className="wpo-testimonial-text">
                                                    <p>{item.des}</p>
                                                    <ul>
                                                        <li>{item.name}</li>
                                                        <li>{item.title}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Testimonials;