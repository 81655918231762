import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import {Link} from 'react-router-dom'
import pimg1 from '../../images/pricing/img-1.png'
import pimg2 from '../../images/pricing/img-2.png'
import pimg3 from '../../images/pricing/img-3.png'
import './style.css'


const Pricing = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

     const [activeTab, setActiveTab] = useState('1');

     const toggle = tab => {
       if(activeTab !== tab) setActiveTab(tab);
     }
   


     const pricing = [
         {
            fImg:pimg1,
            li1:'Network setup & maintenance',
            li2:'System monitoring',
            li3:'2nd Line support for issues',
          
            title:'Essential IT Support Package',    
            price:'50',    
            pakage:'Monthly',    
            link:'/pricing',
         },
         
         {
            fImg:pimg2,
            li1:'Cloud services setup',
            li2:'IT consultation',
            li3:'3rd Line advanced support',
       
            title:'Advanced IT Solutions - Standard',    
            price:'50',    
            pakage:'Monthly',    
            link:'/pricing',
            active:'active',
         },
         
         {
            fImg:pimg3,
            li1:'Full network management',
            li2:'Complete system admin',
            li3:'Priority 2nd & 3rd Line support',
   
            title:'Comprehensive IT Management - Premium',    
            price:'50',    
            pakage:'Monthly',    
            link:'/pricing',
         }, 
         
     ]
     const pricing2 = [
         {
            fImg:pimg1,
            li1:'Full network management',
            li2:'Complete system admin',
            li3:'Cloud integration',
          
            title:'Comprehensive IT Management - Premium',    
            price:'50',    
            pakage:'Yearly',    
            link:'/pricing',
         },
         
         {
            fImg:pimg2,
            li1:'10GB Space',
            li2:'100% High Performence',
            li3:'300GB SSD Disk',
            li4:'5 Domain Hosted Support',
            li5:'Unlimited Support',
            title:'VPS Hosting',    
            price:'50',    
            pakage:'Yearly',    
            link:'/pricing',
            active:'active',
         },
         
         {
            fImg:pimg3,
            li1:'10GB Space',
            li2:'100% High Performence',
            li3:'300GB SSD Disk',
            li4:'5 Domain Hosted Support',
            li5:'Unlimited Support',
            title:'Shared Hosting',    
            price:'50',    
            pakage:'Yearly',    
            link:'/pricing',
         }, 
         
     ]


    return(
        <div className="wpo-pricing-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>{props.pTitle}</h2>
                            <p>Get customized IT solutions to elevate your business. From essential support to comprehensive management, we have the right plan for your needs.</p>
                        </div>
                        <div className="Pricing-header">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggle('1'); }}
                                    >
                                        Monthly
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggle('2'); }}
                                    >
                                        Yearly
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                </div>
                <div className="tab-content">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className="row">
                                {pricing.map((pricing, ptem) => (
                                    <div className="col-lg-4 col-md-6 col-12 custom-grid" key={ptem}>
                                        <div className="wpo-pricing-item">
                                            <h3>{pricing.title}</h3>
                                            <div className="wpo-pricing-icon">
                                                <img src={pricing.fImg} alt="price"/>
                                            </div>
                                            <h2><small>£</small>{pricing.price}<span>/{pricing.pakage}</span></h2>
                                            <div className="wpo-pricing-list">
                                                <ul>
                                                    <li><i className="ti-check-box"></i>{pricing.li1}</li>
                                                    <li><i className="ti-check-box"></i>{pricing.li2}</li>
                                                    <li><i className="ti-check-box"></i>{pricing.li3}</li>
                                               
                                                </ul>
                                            </div>
                                            <Link onClick={ClickHandler} className={`wpo-pricing-btn ${pricing.active}`} to={pricing.link}>Started Now</Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="row">
                                {pricing2.map((pricing, ptem) => (
                                    <div className="col-lg-4 col-md-6 col-12 custom-grid" key={ptem}>
                                        <div className="wpo-pricing-item">
                                            <h3>{pricing.title}</h3>
                                            <div className="wpo-pricing-icon">
                                                <img src={pricing.fImg} alt="price"/>
                                            </div>
                                            <h2><small>$</small>{pricing.price}<span>/{pricing.pakage}</span></h2>
                                            <div className="wpo-pricing-list">
                                                <ul>
                                                    <li><i className="ti-check-box"></i>{pricing.li1}</li>
                                                    <li><i className="ti-check-box"></i>{pricing.li2}</li>
                                                    <li><i className="ti-check-box"></i>{pricing.li3}</li>
                                                    <li><i className="ti-check-box"></i>{pricing.li4}</li>
                                                    <li><i className="ti-check-box"></i>{pricing.li5}</li>
                                                </ul>
                                            </div>
                                            <Link onClick={ClickHandler} className={`wpo-pricing-btn ${pricing.active}`} to={pricing.link}>Started Now</Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </div>
    )
}

export default Pricing;