import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import Homepage3 from '../HomePage3'
import SharedPage from '../SharedPage'
import CloudPage from '../CloudPage'
import WordpressPage from '../WordpressPage'
import VpsPage from '../VpsPage'
import DedicatedPage from '../DedicatedPage'
import EmailPage from '../EmailPage'
import AboutPage from '../AboutPage'
import ServicePage from '../ServicePage'
import ServiceSinglePage from '../ServiceSinglePage'
import PricingPage from '../PricingPage'
import FAQPage from '../FAQPage'
import ShopPage from '../ShopPage'
import ShopSinglePage from '../ShopSinglePage'
import BlogPage from '../BlogPage'
import BlogPageLeft from '../BlogPageLeft'
import BlogPageFullwidth from '../BlogPageFullwidth'
import BlogDetails from '../BlogDetails'
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide'
import BlogDetailsFull from '../BlogDetailsFull'
import ErrorPage from '../ErrorPage'
import ContactPage from '../ContactPage'
import LoginPage from '../LoginPage'
import SignUpPage from '../SignUpPage'
import ForgotPassword from '../ForgotPassword'


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Homepage />} />
          <Route path='home' element={<Homepage />} />
          <Route path='home2' element={<Homepage2 />} />
          <Route path='home3' element={<Homepage3 />} />
          <Route path='shared' element={<SharedPage />} />
          <Route path='cloud' element={<CloudPage />} />
          <Route path='wordpress' element={<WordpressPage />} />
          <Route path='vps' element={<VpsPage />} />
          <Route path='dedicated' element={<DedicatedPage />} />
          <Route path='email' element={<EmailPage />} />
          <Route path='about' element={<AboutPage />} />
          <Route path='service' element={<ServicePage />} />
          <Route path='service-single' element={<ServiceSinglePage />} />
          <Route path='pricing' element={<PricingPage />} />
          <Route path='faq' element={<FAQPage />} />
          <Route path='shop' element={<ShopPage />} />
          <Route path='shop-single' element={<ShopSinglePage />} />
          <Route path='blog' element={<BlogPage />} />
          <Route path='blog-left' element={<BlogPageLeft />} />
          <Route path='blog-fullwidth' element={<BlogPageFullwidth />} />
          <Route path='blog-details' element={<BlogDetails />} />
          <Route path='blog-details-left' element={<BlogDetailsLeftSiide />} />
          <Route path='blog-details-fullwidth' element={<BlogDetailsFull />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='404' element={<ErrorPage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='signup' element={<SignUpPage />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
