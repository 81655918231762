import React from 'react'
import './style.css'

const SearchSection = (props) => {

    const SubmitHandler = (e) =>{
        e.preventDefault()
     }

    return(
        <div className={props.sClass}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="wpo-search-wrap">
                            <div className="wpo-search-area">
                                <form onSubmit={SubmitHandler} className="clearfix">
                                    <div>
                                        <input type="text" className="form-control" placeholder="Enter Your Domain Name"/>
                                    </div>
                                    <div className="s-items">
                                        <select className="select wide">
                                            <option>. Com</option>
                                            <option>.org</option>
                                            <option>.net</option>
                                            <option>.Co</option>
                                            <option>.info</option>
                                            <option>.store</option>
                                        </select>
                                    </div>
                                    <div>
                                        <button type="submit">Search Domain</button>
                                    </div>
                                </form>
                                <div className="wpo-host-list">
                                    <ul>
                                        <li><span>.com</span> $25.12</li>
                                        <li><span>.org</span> $12.50</li>
                                        <li><span>.net</span> $12.50</li>
                                        <li><span>.co</span> $12.50</li>
                                        <li><span>.info</span> $12.50</li>
                                        <li><span>.store</span> $12.50</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchSection;