import React from 'react'
import { Link } from 'react-router-dom'
import service1 from '../../images/service/img-1.png'
import service2 from '../../images/service/img-2.png'
import service3 from '../../images/service/img-3.png'
import service4 from '../../images/service/img-4.png'
import service5 from '../../images/service/img-5.png'
import service6 from '../../images/service/img-6.png'
import './style.css'

const Service = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
    }
      
    const serviceWrap = [
        {
           simg: service1,
           title: "Network Security",
           des: 'Protect your data with advanced security protocols and monitoring.',
           link: "/service-single"
        },
        {
           simg: service2,
           title: "Data Backup & Recovery",
           des: 'Secure your business with reliable data backup and recovery services.',
           link: "/service-single"
        },
        {
           simg: service3,
           title: "Managed IT Services",
           des: 'Comprehensive IT support to keep your systems running smoothly.',
           link: "/service-single"
        },
        {
           simg: service4,
           title: "Cloud Solutions",
           des: 'Seamless cloud integration to enhance accessibility and scalability.',
           link: "/service-single"
        },
        {
           simg: service5,
           title: "Software Development",
           des: 'Custom software solutions for your business needs.',
           link: "/service-single"
        },
        {
           simg: service6,
           title: "IT Consulting",
           des: 'Get expert advice to optimize your IT infrastructure and improve efficiency.',
           link: "/service-single"
        },
    ];

    return (
        <div className="wpo-service-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Who We Are</h2>
                            <p>For the past 12 years, we have been committed to delivering top-tier domain and hosting services, ensuring reliability and support for our clients' online presence</p>
                        </div>
                     
                    </div>
                </div>
                <div className="row">
                    {serviceWrap.slice(0, 6).map((item, srv) => (
                        <div className="col-lg-4 col-md-6 col-12 custom-grid" key={srv}>
                            <div className="wpo-service-item">
                                <div className="wpo-service-icon">
                                    <img src={item.simg} alt="S-icon"/>
                                </div>
                                <div className="wpo-service-text">
                                    <h2>{item.title}</h2>
                                    <p>{item.des}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <Link to="/service" className="see-more-link" onClick={ClickHandler}>
                            See More
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
