import React from 'react';
import {Link} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
import VideoModal from '../../components/ModalVideo'
import './style.css'
import blog1 from '../../images/blog/img-4.jpg'
import blog2 from '../../images/blog/img-5.jpg'
import blog3 from '../../images/blog/img-6.jpg'
import admin from '../../images/blog/admin.jpg'


const BlogList = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


    return(
        <section className="wpo-blog-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={blog1} alt=""/>
                                </div>
                                <ul className="entry-meta">
                                    <li><Link onClick={ClickHandler} to="/blog-details"><img src={admin} alt=""/> By Admin</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details"><i className="fi flaticon-calendar"></i> Sep 25,2021</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details"><i className="fi flaticon-heart"></i> 35</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/blog-details">25 Best Hosting Compani All Over the World</Link></h3>
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now.</p>
                                <Link onClick={ClickHandler} to="/blog-details" className="read-more">Read More...</Link>
                            </div>
                            <div className="post format-video">
                                <div className="entry-media video-holder">
                                    <img src={blog2} alt=""/>
                                    <VideoModal/>
                                </div>
                                <ul className="entry-meta">
                                    <li><Link onClick={ClickHandler} to="/blog-details"><img src={admin} alt=""/> By Admin</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details"><i className="fi flaticon-calendar"></i> Sep 25,2021</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details"><i className="fi flaticon-heart"></i> 35</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/blog-details">Protecting your digital products</Link></h3>
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now.</p>
                                <Link onClick={ClickHandler} to="/blog-details" className="read-more">Read More...</Link>
                            </div>
                            <div className="post format-gallery">
                                <div className="entry-media post-slider">
                                     <img src={blog3} alt=""/>
                                </div>
                                <ul className="entry-meta">
                                    <li><Link onClick={ClickHandler} to="/blog-details"><img src={admin} alt=""/> By Admin</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details"><i className="fi flaticon-calendar"></i> Sep 25,2021</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details"><i className="fi flaticon-heart"></i> 35</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/blog-details">The definitive list of digital products you can sell</Link></h3>
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now.</p>
                                <Link onClick={ClickHandler} to="/blog-details" className="read-more">Read More...</Link>
                            </div>
                            <div className="post format-quote">
                                <ul className="entry-meta">
                                    <li><Link onClick={ClickHandler} to="/blog-details"><img src={admin} alt=""/> By Admin</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details"><i className="fi flaticon-calendar"></i> Sep 25,2021</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details"><i className="fi flaticon-heart"></i> 35</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/blog-details">Selling WordPress Hosting</Link></h3>
                                <p>It is a long established fact that a reader will be distracted by the readable content of a when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using. </p>
                            </div>
                            <div className="pagination-wrapper pagination-wrapper-left">
                                <ul className="pg-pagination">
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details" aria-label="Previous">
                                            <i className="fi ti-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="active"><Link onClick={ClickHandler} to="/blog-details">1</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details">2</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details">3</Link></li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details" aria-label="Next">
                                            <i className="fi ti-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft}/>
                </div>
            </div>
        </section>
     )
        
}

export default BlogList;
