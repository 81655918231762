import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Features2 from '../../components/Features2'
import Pricing from '../../components/Pricing'
import PricingDetails from '../../components/PricingDetails'
import AllPlans from '../../components/AllPlans'
import FaqSection from '../../components/FaqSection'
import Testimonials from '../../components/Testimonials'
import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'


const EmailPage =() => {
    return(
        <Fragment>
            <Navbar Logo={Logo}/>
            <PageTitle pageTitle={'Email Hosting'} pagesub={'Email Hosting'}/> 
            <Features2 hostTitle={'Why Choose Email Hosting?'}/>
            <Pricing pTitle={'Choose Your Email Hosting Plan'}/>
            <PricingDetails/>
            <AllPlans/>
            <FaqSection/>
            <Testimonials/>
            <Newsletter/>
            <Footer ftLogo={Logo} footerClass={'wpo-ne-footer-2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default EmailPage;
