import React from 'react'
import avater from '../../images/testimonials/img-5.jpg'
import blogImg1 from '../../images/blog/img-1.jpg'
import blogImg2 from '../../images/blog/img-2.jpg'
import blogImg3 from '../../images/blog/img-3.jpg'
import {Link} from 'react-router-dom'

import './style.css'

const BlogSection = (props) => {
    
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

     const Blog = [
         {
           blogImg:blogImg1,
           avater:avater,
           title:"The Importance of Marriage in Islam.",
           thumb:'Trending',
           link:"/blog-details"
         },
         {
           blogImg:blogImg2,
           avater:avater,
           title:"Salat is the best exercise for body fitness",
           thumb:'Trending',
           link:"/blog-details"
         },
         {
           blogImg:blogImg3,
           avater:avater,
           title:"Salat is the best exercise for body fitness",
           thumb:'Trending',
           link:"/blog-details"
         },
     ]
    return(
        <section className="wpo-blog-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-md-6 col-sm-10">
                        <div className="section-title">
                            <h2>Latest News</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-blog-grids">
                            {Blog.map((blog, bl) => (
                                    <div className="grid"  key={bl}>
                                        <div className="entry-media">
                                            <img src={blog.blogImg} alt="blog"/>
                                        </div>
                                        <div className="entry-details">
                                            <div className="author">
                                                <img src={blog.avater} alt="blog"/>
                                                <div className="tag">{blog.thumb}</div>
                                            </div>
                                            <h3><Link onClick={ClickHandler} to={blog.link}>{blog.title}</Link></h3>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default BlogSection;