import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import BlogList from '../../components/BlogList'
import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'


const BlogPageLeft =() => {
    return(
        <Fragment>
           <Navbar Logo={Logo}/>
           <PageTitle pageTitle={'Latest News'} pagesub={'Blog'}/> 
           <BlogList blLeft={'order-lg-1'} blRight={'order-lg-2'}/>
            <Newsletter/>
            <Footer ftLogo={Logo}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default BlogPageLeft;







