import React from 'react';
import {Link} from 'react-router-dom'
import './style.css'


const PricingDetails = (props) => {

    return(
        <div className="pricing-details section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="pricing-details-wrap">
                            <form action="cart" className="table-responsive">
                                <table className="cart-wrap">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>
                                                <div className="wpo-pricing-item">
                                                    <div className="p-h3">Single</div>
                                                    <div className="p-h2"><small>$</small>25<span>/Monthly</span></div>
                                                    <div className="btns">
                                                        <Link to="/pricing" className="theme-btn-s2">Buy Now</Link>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="wpo-pricing-item">
                                                    <div className="p-h3">Premium</div>
                                                    <div className="p-h2"><small>$</small>39<span>/Monthly</span></div>
                                                    <div className="btns">
                                                        <Link to="/pricing" className="theme-btn-s2">Buy Now</Link>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="wpo-pricing-item">
                                                    <div className="p-h3">Business</div>
                                                    <div className="p-h2"><small>$</small>49<span>/Monthly</span></div>
                                                    <div className="btns">
                                                        <Link to="/pricing" className="theme-btn-s2">Buy Now</Link>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Monthly visits</td>
                                            <td>Up to 25000</td>
                                            <td>Up to 40000</td>
                                            <td>Unlimited</td>
                                        </tr>
                                        <tr>
                                            <td>Total Websites</td>
                                            <td>1</td>
                                            <td>5</td>
                                            <td>10</td>
                                        </tr>
                                        <tr>
                                            <td>SSD storage</td>
                                            <td>40GB</td>
                                            <td>40GB</td>
                                            <td>40GB</td>
                                        </tr>
                                        <tr>
                                            <td>Bandwidth per month</td>
                                            <td>200GB</td>
                                            <td>400GB</td>
                                            <td>Unlimited</td>
                                        </tr>
                                        <tr>
                                            <td>MySQL Databases</td>
                                            <td>1</td>
                                            <td>Unlimited</td>
                                            <td>Unlimited</td>
                                        </tr>
                                        <tr>
                                            <td>Free Domain Registration</td>
                                            <td><i className="ti-close"></i></td>
                                            <td><i className="ti-check"></i></td>
                                            <td><i className="ti-check"></i></td>
                                        </tr>
                                        <tr>
                                            <td>30-day money back guarantee</td>
                                            <td><i className="ti-check"></i></td>
                                            <td><i className="ti-check"></i></td>
                                            <td><i className="ti-check"></i></td>
                                        </tr>
                                        <tr>
                                            <td>24/7 support</td>
                                            <td><i className="ti-check"></i></td>
                                            <td><i className="ti-check"></i></td>
                                            <td><i className="ti-check"></i></td>
                                        </tr>
                                        <tr>
                                            <td>99.9% Uptime Guarantee</td>
                                            <td><i className="ti-check"></i></td>
                                            <td><i className="ti-check"></i></td>
                                            <td><i className="ti-check"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Subdomains per account</td>
                                            <td>2</td>
                                            <td>20</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>Business Email Accounts</td>
                                            <td>1</td>
                                            <td>2</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Free SSL</td>
                                            <td><i className="ti-close"></i></td>
                                            <td><i className="ti-close"></i></td>
                                            <td><i className="ti-check"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Easy Website Builder</td>
                                            <td><i className="ti-check"></i></td>
                                            <td><i className="ti-check"></i></td>
                                            <td><i className="ti-check"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Success management</td>
                                            <td><i className="ti-check"></i></td>
                                            <td><i className="ti-check"></i></td>
                                            <td><i className="ti-check"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingDetails;