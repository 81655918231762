import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import About from '../../components/about'
import Features from '../../components/Features'
import Testimonials from '../../components/Testimonials'
import VideoSection from '../../components/videoSection'
import Team from '../../components/Team'
import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'


const AboutPage =() => {
    return(
        <Fragment>
            <Navbar Logo={Logo}/>
            <PageTitle pageTitle={'About Us'} pagesub={'About'}/> 
            <About/>
            <Features fClass={'wpo-features-area'}/>
            <VideoSection/>
            <Team/>
            <Testimonials/>
            <Newsletter/>
            <Footer ftLogo={Logo}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default AboutPage;
