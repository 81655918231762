import React from 'react'
import './style.css'

const Newsletter = (props) => {

    const SubmitHandler = (e) => {
      e.preventDefault()
    }
    return(
        <section className={`wpo-news-letter-section ${props.nClass}`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-md-6 col-sm-12 col-12">
                        <div className="wpo-newsletter">
                            <h3>Follow us For ferther information</h3>
                            <div className="wpo-newsletter-form">
                                <form onSubmit={SubmitHandler}>
                                    <div>
                                        <input type="text" placeholder="Enter Your Email" className="form-control" required/>
                                        <button type="submit">Subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default Newsletter;