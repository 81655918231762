import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero from '../../components/hero'
import SearchSection from '../../components/SearchSection'
import Features from '../../components/Features'
import Pricing from '../../components/Pricing'
import Service from '../../components/Service'
import CtaSection from '../../components/cta'
import Testimonials from '../../components/Testimonials'
import BlogSection from '../../components/BlogSection'
import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
import Flogo from '../../images/logo-2.png'


const HomePage2 =() => {
    return(
        <Fragment>
            <Navbar headerClass={'s2'} Logo={Logo}/>
            <Hero heroclass={'wpo-hero-style-2'}/>
            <SearchSection sClass={'wpo-search-section-2'}/>
            <Features fClass={'wpo-features-section-2'} bundle={'wpo-features-bundle'}/>
            <Pricing pTitle={'Our Pricing Plan'}/>
            <Service/>
            <CtaSection/>
            <Testimonials/>
            <BlogSection/>
            <Newsletter nClass={'wpo-news-letter-section-2'}/>
            <Footer ftLogo={Flogo} ftClass={'wpo-site-footer-2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage2;