import React from 'react'
import { Link } from 'react-router-dom'
import fimg1 from '../../images/features/img-1.png'
import fimg2 from '../../images/features/img-2.png'
import fimg3 from '../../images/features/img-3.png'

import './style.css'

const Features2 = (props) => {

    const feature = [
        {
          cIcon:'fi flaticon-startup',
          cImg:fimg1,
          heading:"Faster Loading Speed",
          pra:"Protect your data with advanced security protocols and encryption.",
        },
        {
          cIcon:'fi flaticon-label',
          cImg:fimg2,
          heading:"Scalable Solutions",
          pra:"Easily adapt to business growth with scalable IT solutions.",
          cl:'wpo-features-item-2'
        },
        {
          cIcon:'fi flaticon-support',
          cImg:fimg3,
          heading:"Expert IT Consultation",
          pra:"Benefit from professional guidance to optimize your IT infrastructure.",
        },
    ]

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-features-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>{props.hostTitle}</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {feature.map((ftr, fitem) => (
                        <div className="col-lg-4 col-md-6 custom-grid col-12" key={fitem}>
                            <div className={`wpo-features-item ${ftr.cl}`}>
                                <div className="wpo-features-single">
                                    <div className="wpo-features-icon">
                                        <i className={ftr.cIcon}></i>
                                    </div>
                                    <div className="wpo-features-text">
                                        <h2>{ftr.heading}</h2>
                                        <p>{ftr.pra}</p>
                                        <Link onClick={ClickHandler} to="/service-single">See More...</Link>
                                    </div>
                                </div>
                                <div className="wpo-features-img">
                                    <img src={ftr.cImg} alt="shape"/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Features2;