import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar'
import Hero from '../../components/hero'
import SearchSection from '../../components/SearchSection'
import Features from '../../components/Features'
import Pricing from '../../components/Pricing'
import Service from '../../components/Service'
import CtaSection from '../../components/cta'
import Testimonials from '../../components/Testimonials'
import BlogSection from '../../components/BlogSection'
import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'


const HomePage = () => {
    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <Hero heroclass={'wpo-hero-style-1'} />

            <Features fClass={'wpo-features-area'} />

            <Service />            
            <CtaSection />

            <Testimonials />

            <Newsletter />
            <Footer ftLogo={Logo} />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage;