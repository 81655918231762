import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'

const Hero = (props) => {

    return(
        <section className={props.heroclass}>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 col-md-12">
                        <div className="wpo-hero-text">
                            <div className="wpo-hero-title">
                                <h2>Welcome to Tech Support Inc: Your Trusted IT  Consultancy</h2>
                            </div>
                            <div className="wpo-hero-para">
                                <p>Are you in search of top-tier IT solutions that cater to your business's unique needs?  Look no further! Welcome to Tech Support Inc, where exceptional IT services meet  personalized solutions. Meet our dedicated IT Technical Architect, Alex, based in  the heart of Marylebone, London.</p>
                            </div>
                            <div className="btns">
                                <Link to="/contact" className="theme-btn">Get In Touch</Link>
                                <Link to="/about" className="theme-btn-s2">Know More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-vec">
            </div>
        </section>
    )
}

export default Hero;